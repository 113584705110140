<template>
  <div class="outside-copyright mt-4 text-center">
    <p class="font-16-500">
      By continuing you agree to GoFindBuild LLC.
      <span class="d-block text-center">
        <router-link to="/terms">Terms of Service</router-link> and
        <router-link to="/privacy-policy">Privacy Policy.</router-link>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "login-footer",
}
</script>

<style scoped></style>
