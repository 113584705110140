/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
//import VueToast from "vue-toast-notification"
import { BSpinner, FormRadioPlugin } from "bootstrap-vue"
import Multiselect from "vue-multiselect"
import { ClientTable } from "vue-tables-2"
import Dayjs from "vue-dayjs"
import moment from "moment"
import "./registerServiceWorker"
import "./vee-validate"
import ArgonDashboard from "./plugins/argon-dashboard"
import store from "./store/store"
import ToggleButton from "vue-js-toggle-button"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"

import DefaultButton from "@/components/DefaultButton"
import DefaultModal from "@/components/Modals/DefaultModal"
import VueJsonToCsv from "vue-json-to-csv"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import ToastMessage from "@/components/ToastMessage"
import { VueReCaptcha } from "vue-recaptcha-v3"
import { BootstrapVue } from "bootstrap-vue"
import TextInput from "@/components/MaterialDesign/TextInput"
import LoginFooter from "@/components/LoginFooter"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

Vue.use(BootstrapVue)

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: { autoHideBadge: true },
})

Vue.use(Toast, {
  position: "top-right",
  duration: 6000,
  hideProgressBar: true,
})

Vue.use(Vuetify)

let vueTablesOptions = {
  theme: "bootstrap4",
  sortIcon: {
    base: "ml-2 fas",
    up: "fa-sort-up",
    down: "fa-sort-down",
    is: "fa-sort",
  },
  skin: "table app-table",
  pagination: {
    nav: "scroll",
  },
}
Vue.config.productionTip = false
Vue.prototype.moment = moment
Vue.component("b-spinner", BSpinner)
Vue.component("multiselect", Multiselect)
Vue.use(ClientTable, vueTablesOptions)
Vue.use(Dayjs)
Vue.use(ArgonDashboard)
Vue.use(FormRadioPlugin)
Vue.use(ToggleButton)
Vue.component("default-button", DefaultButton)
Vue.component("text-input", TextInput)
Vue.component("vue-json-to-csv", VueJsonToCsv)
Vue.component("default-modal", DefaultModal)
Vue.component("toast-message", ToastMessage)
Vue.component("login-footer", LoginFooter)
new Vue({
  vuetify: new Vuetify({
    icons: {
      iconfont: "mdi", // Specify MDI icons
    },
  }),
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
