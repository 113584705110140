import Vue from "vue"
import Router from "vue-router"
import AuthLayout from "@/layout/AuthLayout"
import Layout from "@/layout/Layout"
import { employeesRouter } from "./employees"
import { jobsRouter } from "./jobs"
import { toolboxRouter } from "./toolbox"
import { tradePartnersRouter } from "./trade-partners"
import { trainingsRouter } from "./trainings"
import { vendorsRouter } from "./vendors"
import { adminRouter } from "./admin"
import { myJobsRouter } from "./my-jobs"
import HomePage from "@/views/HomePage"
import store from "@/store/store.js"

Vue.use(Router)

const routes = [
  {
    path: "/",
    component: HomePage,
  },
  // TODO: Remove this Icons view before launch
  {
    path: "/icons",
    component: () => import(/* webpackChunkName: "demo" */ "@/views/Icons.vue"),
  },
  {
    path: "/",
    redirect: "dashboard",
    component: Layout,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "@/views/Dashboard.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
      },
      {
        path: "/profile/:profileId/:type?",
        name: "profile-id",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
      },
      {
        path: "/messages",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/Messages.vue"),
      },
      ...employeesRouter,
      ...jobsRouter,
      ...toolboxRouter,
      ...tradePartnersRouter,
      ...trainingsRouter,
      ...vendorsRouter,
      ...myJobsRouter,
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    meta: {
      authRequired: false,
    },
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/Login.vue"),
      },
      {
        path: "/signup",
        name: "signup",
        component: () =>
          import(/* webpackChunkName: "Signup" */ "@/views/Register.vue"),
      },
      {
        path: "/confirm-email",
        name: "confirm-email",
        component: () =>
          import(
            /* webpackChunkName: "confirmEmail" */ "@/views/ConfirmEmail.vue"
          ),
      },
      {
        path: "/complete-profile",
        name: "complete-profile",
        component: () =>
          import(
            /* webpackChunkName: "completeProfile" */ "@/views/CompleteProfile.vue"
          ),
      },
      {
        path: "/reset-password",
        name: "resetpassword",
        component: () =>
          import(
            /* webpackChunkName: "ResetPassword" */ "@/views/ResetPassword.vue"
          ),
      },
    ],
  },
  ...adminRouter,
  {
    path: "/privacy-policy",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/PrivacyPolicy.vue"),
  },
  {
    path: "/terms",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Terms.vue"),
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "[request]" */ "@/views/404.vue"),
  },
]

// propagate metadata recursively to child routes
function propagateMetadata(routes, meta) {
  routes.forEach((route) => {
    if (route.meta === undefined) {
      route.meta = meta
    }
    if (route.children !== undefined) {
      propagateMetadata(route.children, route.meta)
    }
  })
}

propagateMetadata(routes, {})

const router = new Router({
  mode: "history",
  linkExactActiveClass: "active",
  routes: routes,
})

router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem("GFB_ACCESS_TOKEN")
  const userProfile = store.state.userProfile
  //check page is protected or not
  if (to.meta.authRequired === true) {
    //access check
    if (!accessToken || !userProfile) {
      next({ path: "/login", query: { redirect: to.fullPath } })
    } else {
      if (to.meta.admin === true) {
        if (store.state.user.role == "support") {
          return next()
        } else {
          router.push("/dashboard")
        }
      } else {
        if (store.state.user.role == "support") {
          return next("admin/dashboard")
        } else {
          next()
        }
      }
    }
  } else {
    return next()
  }
})

export default router
