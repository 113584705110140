<template>
  <div class="experience-slider-container">
    <div class="exp-label">
      <span class="heading">Experience Level</span>
      <span class="exp-level">{{
        ratingLabels[expRating] || "Entry Level"
      }}</span>
    </div>
    <div class="slider-wrapper">
      <v-slider
        class="experience-slider profile-slider"
        :class="{ 'edit-mode': editMode }"
        v-model="expRating"
        :max="ratingLabels.length - 1"
        thumb-label="always"
        :ticks="editMode ? 'always' : false"
        :tick-labels="ratingLabels"
        tick-size="6"
        :thumb-size="22"
        color="#1cd47e"
        :readonly="editMode ? false : true"
      >
        <template v-slot:thumb-label="{ modelValue }">
          {{ ratingLabels[modelValue] }}
        </template>
      </v-slider>
    </div>
    <div class="experience-desc" v-if="editMode">
      <span class="heading">{{ ratingLabels[expRating] }}</span>
      <p class="description">
        {{
          ratingDescription[ratingLabels[expRating].toLowerCase()] ||
          ratingDescription["apprentice"]
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading-spinner",
  props: {
    rating: {
      type: Number,
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expRating: this.rating ?? 0,
      editMode: this.isEditing ?? true,
      ratingLabels: ["Apprentice", "Skilled", "Journeyman", "Master"],
      ratingDescription: {
        apprentice:
          "Job seeker has limited experience and some formal training, but less than 2 years of work experience with this trade",
        skilled:
          "Job seeker has knowledge, training, skills, and a minimum 2 years of work experience with this trade",
        journeyman:
          "Job seeker can work independently with minimal supervision and has a minimum 4 years of work experience with this trade",
        master:
          "Job seeker has highest level of training and skills, can supervise workers, and has a minimum 6 years of work experience with this trade",
      },
    }
  },
  watch: {
    expRating(newVal) {
      this.$emit("on-change", newVal)
    },
    isEditing(newVal) {
      this.editMode = newVal
    },
    rating(newVal) {
      this.expRating = newVal
    },
  },
}
</script>

<style lang="scss" scoped>
.experience-slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  gap: 16px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #525e77;
  }

  div.rating {
    display: flex;
    width: 42px;
    height: 32px;
    padding: 5px 16px 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid var(--Gray-500, #677690);
    background: var(--White, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--Gray-900, #343946);
    text-align: center;
    margin-left: 30px;
  }

  .slider-wrapper {
    position: relative;
    width: 100%;
  }
}

.exp-label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .heading {
    color: var(--Gray-800, #3a4252);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: unset;
  }
  .exp-level {
    padding: 4px 8px;
    align-items: center;
    border-radius: 8px;
    background: var(--Gray-100, #eceef2);
    color: var(--Gray-800, #3a4252);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}

.experience-desc {
  display: flex;
  min-height: 90px;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Gray-50, #f6f7f9);

  .heading {
    color: var(--Gray-800, #3a4252);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: unset;
  }

  .description {
    color: var(--Gray-800, #3a4252);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
</style>
